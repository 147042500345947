<template>
  <div>
    <modal ref="transactionApprovalModal">
				<h4>
          Approve Transaction
        </h4>
				<div class="text-sm mt-5 mb-10">
					Are you sure you want to approve this credit transaction?
				</div>

				<button type="button" class="btn btn-blue" @click.prevent="$refs.transactionApprovalModal.close">
					{{ clearing ? 'Marking Successfull...' : 'Approve' }}
				</button>
			</modal>
    <datatable :data="transactions" :columns="columns" :actions="actions" />
  </div>
</template>
<script>
export default {
  props: {
    staff: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      transactionToApprove: null,
      columns: [
        {
          name: "description",
          th: "Description",
        },
        {
          name: "category",
          th: "Category",
          render: (transaction, category) => `${category || ""}`,
        },
        /*{
            name: 'type',
            th: 'Transaction Type',
          },*/
        {
          name: "amount",
          th: "Amount",
          render: (transaction, amount) =>
            `₦ ${this.$options.filters.currency(amount)}`,
        },
        {
          name: "status",
          th: "Status",
          render: (transaction, status) => this.transactionStatusBadge(status),
        },
        {
          name: "created_at",
          th: "Date",
          render: (transaction, created_at) =>
            this.$options.filters.dateFormat(created_at, "D, dd M Y h:ia"),
            
        },
      ],
      actions: [
        {
          text: 'Approve',
          class: 'border-blue-500 text-blue-500 text-white hover:bg-blue',
          show: (transaction) => transaction.category === "repayment-transfer" && transaction.status === "pending",
          action: (transaction) => this.approveTransaction(transaction)
        }
      ]
    };
  },
  computed: {
    transactions() {
      return this.staff?.personal_account?.personal_transactions || [];
    },
  },
  methods: {
    approveTransaction(transaction) {
      this.transactionToApprove = transaction.reference;
      this.$refs.transactionApprovalModal.open();
    }
  }
};
</script>